/*
 *** 快速連結
 */

const systemQuickLinkConfig = [
    {
        label: '線上繳費',
        link: 'https://fee.ls-ecommerce.com.tw/pay'
    },
    {
        label: 'Finfo',
        link: 'https://leishan.finfo.tw/'
    },
    {
        label: '考照系統',
        link: 'https://certification.leishan.app/'
    },
    {
        label: '獎項系統',
        link: 'https://bms.leishan.app/award'
    },
    {
        label: 'DREW',
        link: 'https://drew.leishan.app/'
    },
    {
        label: '磊山\n知識家',
        link: 'https://km.leishan.app/'
    },
    {
        label: '磊山\ne學院',
        link: 'https://elearning.leishan.app/cltcms/leishan_logout.jsp'
    },
    {
        label: '考照報名',
        link: 'https://eip.leishan.com.tw:8080/Default.aspx?web=/'
    },
    {
        label: '線上報聘',
        link: 'https://eip.leishan.com.tw:8080/LSLab/Default.aspx'
    }
]

const agentQuickLinkConfig = {
    applicationLink:
        'https://docs.google.com/forms/d/e/1FAIpQLSe6UIM3QkbNCzuCtlI9gj_VCuxdrolQ0AWSFx4sjQerFQVX0A/viewform',
    //agent(代理專區),eBook(電子書),proposal(建議書)
    linkItems: [
        {
            label: '保誠人壽',
            logo: 'pic_保誠人壽@3x.webp',
            link: {
                agent: 'https://www.eservice.pcalife.com.tw/sso/bragentlogin',
                proposal: 'https://www.eservice.pcalife.com.tw/agent/'
            }
        },
        {
            label: '友邦人壽',
            logo: 'pic_友邦人壽@3x.webp',
            link: {
                agent: 'https://mypageapp.aia.com.tw/zh-tw/tw.war/babk/BK/',
                eBook: '',
                proposal: 'https://mypageapp.aia.com.tw/zh-tw/tw.war/babk/'
            }
        },
        {
            label: '元大人壽',
            logo: 'pic_元大人壽@3x.webp',
            link: {
                agent: 'https://mcsp.yuantalife.com.tw/mcsp/verify.aspx',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '台灣人壽',
            logo: 'pic_台灣人壽@3x.webp',
            link: {
                agent: 'https://customer.taiwanlife.com/eSponsor_New/',
                eBook:
                    'https://ebook.taiwanlife.com/bb/byCompanyCode.aspx?companycode=Broker',
                proposal: 'https://proposal.taiwanlife.com/login'
            }
        },
        {
            label: '全球人壽',
            logo: 'pic_全球人壽@3x.webp',
            link: {
                agent: 'https://mpos.transglobe.com.tw/app3/#/login-br',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '新光人壽',
            logo: 'pic_新光人壽@3x.webp',
            link: {
                agent: 'https://brm.skl.com.tw/PRMWeb/Security/Login',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '遠雄人壽',
            logo: 'pic_遠雄人壽@3x.webp',
            link: {
                agent: 'https://bw.fglife.com.tw/bw/loginBroker.action',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '宏泰人壽',
            logo: 'pic_宏泰人壽@3x.webp',
            link: {
                agent: 'https://life.hontai.com.tw/HontaiGP/coagent.aspx',
                eBook: '',
                proposal: 'https://proposal.hontai.com.tw/Home/Login'
            }
        },
        {
            label: '安聯人壽',
            logo: 'pic_安聯人壽@3x.webp',
            link: {
                agent:
                    'https://es.allianz.com.tw/partner/PartnershipPortal/https://es.allianz.com.tw/partner/PartnershipPortal/',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '安達國際人壽',
            logo: 'pic_安達人壽@3x.webp',
            link: {
                agent:
                    'https://www.chubblife-vul.com.tw/acelifevul/house/agentLogin.do',
                eBook: 'https://life.chubb.com/tw-zh/partners/',
                proposal: 'https://esfp.chubblife-vul.com.tw/illustration/login'
            }
        },

        {
            label: '安達產險',
            logo: 'pic_安達人壽@3x.webp',
            link: {
                agent: 'https://tpeb2b.chubb.com.tw/',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '和泰產險',
            logo: 'pic_和泰產險@3x.webp',
            link: {
                agent: 'https://agent.hotains.com.tw/iz/login',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '富邦人壽',
            logo: 'img_富邦人壽@3x.webp',
            link: {
                agent:
                    'https://agent.fubonlife.com.tw/Agent/WebModule/Accounts/Input.asp',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '富邦產險',
            logo: 'pic_富邦產險@3x.webp',
            link: {
                agent: 'https://b2b.518fb.com/ec/b2b_index.jsp',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '第一金人壽',
            logo: 'img_第一金人壽@3x.webp',
            link: {
                agent: 'https://www.firstlife.com.tw/AvivaEAgent/',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '法國巴黎人壽',
            logo: 'img_法國巴黎人壽@3x.webp',
            link: {
                agent: 'https://my.cardif.com.tw/B2B/',
                eBook: '',
                proposal: 'https://my.cardif.com.tw/B2B/BrokerDownload'
            }
        },
        {
            label: '臺灣產物保險',
            logo: 'img_台灣產物@3x.webp',
            link: {
                agent: 'https://dp.tfmi.com.tw/DigitalPortal/Account/Login',
                eBook: '',
                proposal: ''
            }
        },
        {
            label: '台新人壽',
            logo: 'pic_台新人壽@3x.webp',
            link: {
                agent: 'https://myweb.taishinlife.com.tw/TbkS/',
                eBook: '',
                proposal: ''
            }
        }
    ]
}

export { systemQuickLinkConfig, agentQuickLinkConfig }
